import { useCurrentUser } from '../../graphql/hooks'
import { useGetLatestEventsQuery } from '../../graphql/queries/main/mainFeed.query.gen'
import EventFeed from '../../components/EventFeed'
import FeedActions from '../../components/FeedActions'

import * as styles from './LatestEventsPage.styles'

const Main: React.FC = (props) => {
  const { city, selectedProfile } = useCurrentUser()

  const { data, loading, fetchMore } = useGetLatestEventsQuery({
    variables: {
      cityId: city.id,
      pagination: {
        first: 7,
      },
    },
  })

  const eventsConnection = data?.getLatestEvents
  const hasNextPage = eventsConnection?.pageInfo.hasNextPage

  const loadMoreEvents = () => {
    const after = eventsConnection?.pageInfo.endCursor

    if (after && hasNextPage) {
      fetchMore({
        variables: {
          cityId: city.id,
          pagination: {
            first: 7,
            after,
          },
        },
      })
    }
  }

  return (
    <section {...props} css={styles.container}>
      <EventFeed
        loading={loading}
        events={eventsConnection?.edges.map((edge) => edge.node)}
        hasNextPage={hasNextPage}
        loadMoreEvents={loadMoreEvents}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <FeedActions hideFilter profile={selectedProfile as any} />
      </EventFeed>
    </section>
  )
}

export default Main
